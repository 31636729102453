import { Field, Form, Formik } from "formik";
import _ from "lodash";
// @ts-ignore
import { charsets, PasswordPolicy } from "password-sheriff";
import React, { useState } from "react";
import * as yup from "yup";
import { addError, addSuccess, useNotificationStore } from "../../utils/errors";
import { urlService } from "../../_services/urlService";
import TextFormikField from "../FormikFields/TextFormikField";
import WaitingAnimation from "../WaitingAnimation";

export default () => {
  // We store a successful registration so that we stop showing the whole form
  const [wasSucessful, setSuccesful] = useState(false);

  const RegistrationSchema = yup.object().shape({
    firstName: yup.string().required("Fehlender Wert"),
    lastName: yup.string().required("Fehlender Wert"),
    email: yup.string().email("Invalid email").required("Bitte geben Sie Ihre Email an"),
    password: yup
      .string()
      .required("Bitte geben Sie ein Passwort ein")
      .test(
        "meets-password-policy",
        "Mindestens zehn Zeichen. Bitte Groß- und Kleinbuchstaben sowie Zahlen oder Sonderzeichen verwenden",
        (value) => {
          try {
            passwordPolicy.assert(value);
            return true;
          } catch (error) {
            return false;
          }
        }
      ),
    password_confirm: yup
      .string()
      .equals([yup.ref("password")], "Die Passwörter stimmen nicht überein")
      .required("Bitte bestätigen Sie das Passwort"),
  });

  // FIXME: Define this centrally
  const passwordPolicy = new PasswordPolicy({
    length: { minLength: 10 },
    containsAtLeast: {
      atLeast: 3,
      expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
    },
  });

  return (
    <div className="w-full" hidden={wasSucessful}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          password_confirm: "",
        }}
        validationSchema={RegistrationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const email = values.email.toLowerCase(); // Only lowercase is valid.
          // Old values for compatibility
          let name = values.firstName;
          let surname = values.lastName;
          // We overwrite the password_confirm value. No need to send it. Won't get logged, this way.
          const messageBody = { ...values, email, name, surname, password_confirm: undefined };

          setSubmitting(true);
          let url = urlService.getUrl("/user/register");

          try {
            await urlService.getAxios().post(url, messageBody, {
              headers: {
                "Content-Type": "application/json",
              },
            });
          } catch (err: any) {
            if (err.response?.data?.displayMessage) {
              addError(err.response.data.displayMessage);
            } else {
              addError("Die Registrierung konnte nicht durchgeführt werden");
            }
            return;
          }

          addSuccess(
            "Registrierung erfolgreich. Ihr Zugang muss noch aktiviert werden. Wir haben Ihnen eine Mail mit dem dazugehörigen Link geschickt."
          );

          setSuccesful(true);

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="flex">
              <div className="form-group flex-1 mr-1">
                <label htmlFor="firstName">Vorname</label>
                <Field disableAutocomplete component={TextFormikField} className="w-full" name="firstName" />
              </div>
              <div className="form-group flex-1 ml-1">
                <label htmlFor="lastName">Nachname</label>
                <Field disableAutocomplete component={TextFormikField} className="w-full" name="lastName" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field disableAutocomplete type="email" component={TextFormikField} className="w-full" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field newPassword component={TextFormikField} className="w-full" name="password" type="password" />
            </div>
            <div className="form-group">
              <label htmlFor="password_confirm">Password bestätigen</label>
              <Field disableAutocomplete component={TextFormikField} className="w-full" name="password_confirm" type="password" />
            </div>
            {/* <Button type="submit" disabled={isSubmitting}>Login</Button> */}
            <div className="form-group">
              {isSubmitting ? <WaitingAnimation /> : null}
              <div style={{ float: "right" }}>
                <button className="button button-primary" type="submit" disabled={isSubmitting || _.some(errors)}>
                  registrieren
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
